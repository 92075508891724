<template>
  <div
    class="modalPopup print_modal sanchae_production_log_form_print_modal"
    v-if="modalClose"
  >
    <div class="modal_header">
      <h2 class="title">생산일보</h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
    </div>
    <div class="modal_body">
      <div class="sanchae_production_log_form_print_table">
        <div class="top">
          <h2>생산일보</h2>
          <div class="admission">
            <table>
              <tr>
                <th class="colHead"><b>작성</b></th>
                <th class="colHead"><b>승인</b></th>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
        <div class="table1_wrap">
          <table class="table1">
            <colgroup>
              <col v-for="(n, index) in 4" :key="index" />
            </colgroup>
            <tbody>
              <tr>
                <td class="colHead">생산일자</td>
                <td>{{ productionDate }}</td>
                <td class="colHead">제품명</td>
                <td>
                  {{ findInfoFromId(products, target_lot.product_id).name
                  }}{{
                    findInfoFromId(
                      products,
                      target_lot.product_id,
                    ).standard.trim() != '' &&
                    findInfoFromId(products, target_lot.product_id).standard !=
                      null
                      ? ` - ` +
                        findInfoFromId(products, target_lot.product_id).standard
                      : ''
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mes_tbl worker_info">
          <table>
            <tbody>
              <tr>
                <th class="colHead">작업인원</th>
                <td colspan="3">
                  <input
                    type="text"
                    :value="numberOfWorkers"
                    @input="numberOfWorkers = $event.target.value"
                  />
                </td>
              </tr>
              <tr v-for="(processName, index) in getProcessName" :key="index">
                <th class="colHead">{{ processName }}</th>
                <td colspan="3">
                  <input
                    type="text"
                    :value="workersInfo[index]"
                    @input="workersInfo[index] = $event.target.value"
                  />
                </td>
              </tr>
              <tr>
                <th class="colHead">기타</th>
                <td colspan="3">
                  <input
                    type="text"
                    :value="etc"
                    @input="etc = $event.target.value"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table2_wrap">
          <div
            v-for="(list, index) in filteredTrackingResource"
            :key="index"
            :class="{ resourceTable1: index == 0, resourceTable2: index == 1 }"
          >
            <table class="mes_tbl">
              <thead>
                <tr>
                  <th>원료명</th>
                  <th>투입량</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in list" :key="index">
                  <td>
                    {{ item != null ? getName(item) : '' }}
                  </td>
                  <td class="text_right">
                    <b>{{
                      item != null
                        ? $makeComma(
                            $decimalDiv(
                              item.quantity,
                              getUnitConversion(item.material_id),
                            ),
                          )
                        : ''
                    }}</b
                    >{{
                      item != null
                        ? `(${findInfoFromId(units, item.unit_id).name})`
                        : ''
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="table3_wrap">
          <table>
            <colgroup>
              <col v-for="(n, index) in 4" :key="index" />
            </colgroup>
            <tbody>
              <tr>
                <td class="colHead" colspan="4">제조방법 및 작업지시사항</td>
              </tr>
              <tr>
                <td colspan="4">
                  {{ target_lot.detail }}
                </td>
              </tr>
              <tr>
                <td class="colHead">포장방법 및 단위</td>
                <td>
                  {{
                    getProductIn
                      .map(x => findInfoFromId(products, x.product_id).standard)
                      .join(' / ')
                  }}
                </td>
                <td class="colHead">생산량</td>
                <td>
                  <b>{{
                    $makeComma(getMinPassQuantity(target_lot.bom_info))
                  }}</b
                  >{{
                    `(${
                      findInfoFromId(
                        units,
                        findInfoFromId(products, target_lot.product_id)
                          .stock_unit_id,
                      ).name
                    })`
                  }}
                </td>
              </tr>
              <tr v-for="product in getProductIn" :key="product.id">
                <td class="colHead">포장제품 및 규격</td>
                <td>
                  {{ findInfoFromId(products, product.product_id).name }}
                  {{
                    findInfoFromId(products, product.product_id).standard !=
                    null
                      ? ` - ${
                          findInfoFromId(products, product.product_id).standard
                        }`
                      : ''
                  }}
                </td>
                <td class="colHead">수량</td>
                <td>
                  <b>{{ product.quantity }}</b>
                  {{ `(${findInfoFromId(units, product.unit_id).name})` }}
                </td>
              </tr>
              <tr>
                <td class="colHead">작성자</td>
                <td :colspan="!systemCompany.expire_yn ? 3 : 1">
                  {{ getWorkerName }}
                </td>
                <td class="colHead" v-show="systemCompany.expire_yn">
                  유통기한
                </td>
                <td v-show="systemCompany.expire_yn">
                  {{ getExpireDate(target_lot.product_in[0]) }} 까지
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2 btn_print" @click="handlerPrint()">
        <i class="ico_print"></i>출력하기
      </button>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';

import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import { mapGetters } from 'vuex';
import { getExpireDate, yyyy년MM월dd일 } from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  props: ['productionDate'],
  data() {
    return {
      modalClose: true,
      numberOfWorkers: '',
      workersInfo: [],
      etc: '',
    };
  },
  computed: {
    ...mapGetters({
      target_lot: 'getTargetLotFromLotTracking',
      tracking_resource: 'getLotTrackingResource',
      units: 'getUnitCodes',
      products: 'getProduct',
      materials: 'getMaterial',
      unitConversions: 'getUnitConversion',
      systemCompany: 'getSystemCompany',
      userId: 'getUserId',
      users: 'getUsersTempFromUserPage',
      process: 'getProcess',
    }),
    filteredTrackingResource() {
      let filteredList = this.lodash
        .clonedeep(this.tracking_resource)
        .filter(
          x =>
            (x.material_id != null &&
              this.findInfoFromId(this.materials, x.material_id)
                .resource_type_id == 1) ||
            x.product_id != null,
        );

      let clone = this.lodash
        .clonedeep(filteredList)
        .filter(x => x.material_id != null);
      const obj = clone.reduce(function(rv, x) {
        (rv[x['material_id']] = rv[x['material_id']] || []).push(x);
        return rv;
      }, {});
      console.log(obj);
      let list_ = [];
      for (let k in obj) {
        if (k != 'undefined' && k != 'null') {
          list_.push({
            unit_id: obj[k][0].unit_id,
            material_id: k,
            element: obj[k],
            quantity: obj[k]
              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0),
          });
        }
      }

      let clone2 = this.lodash.clonedeep(
        filteredList.filter(x => x.product_id != null),
      );
      const obj2 = clone2.reduce(function(rv, x) {
        (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
        return rv;
      }, {});
      console.log(obj2);
      let list_2 = [];
      for (let k in obj2) {
        if (k != 'undefined' && k != 'null') {
          list_2.push({
            unit_id: obj2[k][0].unit_id,
            product_id: k,
            element: obj2[k],
            quantity: obj2[k]
              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0),
          });
        }
      }

      filteredList = list_.concat(list_2);

      let listLen =
        filteredList.length % 2 == 0
          ? filteredList.length / 2
          : filteredList.length / 2 + 1;
      let resourceList = [
        filteredList.slice(0, listLen),
        filteredList.slice(listLen),
      ];

      if (resourceList[0].length != resourceList[1].length) {
        resourceList[1].push(null);
      }

      return resourceList;
    },
    getWorkerName() {
      return this.users.find(x => x.account == this.userId).name;
    },
    getProcessName() {
      const targetLotProcess = this.target_lot.bom_info.bom_process.map(x => {
        return this.process.find(y => y.id == x.process_id).name;
      });

      console.log('targetLotProcess 확인', targetLotProcess);
      return targetLotProcess;
    },
    getProductIn() {
      return this.target_lot.product_in;
    },
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    handlerPrint() {
      const app = document.querySelector('#app');

      const productionLogTable = document.querySelector(
        '.sanchae_production_log_form_print_modal .modal_body .sanchae_production_log_form_print_table',
      );
      const printDiv = document.createElement('div');
      let productionLogTableClone = productionLogTable.cloneNode(true);
      printDiv.className = 'sanchae_production_log_form_print_wrap';
      document.body.appendChild(printDiv);
      printDiv.append(productionLogTableClone);
      app.style.display = 'none';
      window.print();
      app.style.display = 'block';
      document.body.removeChild(printDiv);
    },
    getUnitConversion(material_id) {
      const target_mat = this.findInfoFromId(this.materials, material_id);
      const foundItem = this.unitConversions.find(
        x =>
          x.in_unit_id == target_mat.incoming_unit_id &&
          x.out_unit_id == target_mat.using_unit_id,
      );
      if (foundItem != null) {
        return foundItem.cvr_ratio;
      } else {
        return 1;
      }
    },
    getMinPassQuantity(bom_info) {
      if (bom_info != undefined) {
        let max_index = 0;
        bom_info.bom_process.forEach(el => {
          if (el.index > max_index) max_index = el.index;
        });
        console.log('max_index', max_index);
        let min_pass_quantity = 99999999999999;
        const filterLastIndexProcess = this.lodash.clonedeep(
          bom_info.bom_process.filter(x => x.index == max_index),
        );
        filterLastIndexProcess.forEach(el => {
          if (el.production_info == null) min_pass_quantity = 0;
          else if (el.production_info.pass_quantity < min_pass_quantity)
            min_pass_quantity = el.production_info.pass_quantity;
        });

        return Math.ceil(min_pass_quantity);
      } else {
        return 0;
      }
    },
    getExpireDate(item) {
      const products = this.findInfoFromId(this.products, item.product_id);
      return yyyy년MM월dd일(
        new Date(
          getExpireDate(
            new Date(item.create_time),
            products.expire_year,
            products.expire_month,
            products.expire_day,
          ),
        ),
      );
    },
    getName(item) {
      if (item.material_id != null) {
        // 원자재
        let materialName = this.findInfoFromId(this.materials, item.material_id)
          .name;
        if (
          this.findInfoFromId(
            this.materials,
            item.material_id,
          ).standard.trim() != '' &&
          this.findInfoFromId(this.materials, item.material_id).standard != null
        ) {
          materialName =
            materialName +
            ' - ' +
            this.findInfoFromId(this.materials, item.material_id).standard;
        }
        return materialName;
      } else {
        // 제품
        let productName = this.findInfoFromId(this.products, item.product_id)
          .name;
        if (
          this.findInfoFromId(this.products, item.product_id).standard.trim() !=
            '' &&
          this.findInfoFromId(this.products, item.product_id).standard != null
        ) {
          productName =
            productName +
            ' - ' +
            this.findInfoFromId(this.products, item.product_id).standard;
        }
        return productName;
      }
    },
  },
  async created() {
    if (this.units.lenght < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.unitConversions.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.process.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }

    this.workersInfo = new Array(this.getProcessName.length);
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    // size: A4 !important;
    margin: auto 0 !important;
  }
  @page :first {
    margin-top: 0 !important;
  }
}
</style>
